:root{
    --mainTransition: all 0.3s linear;
}

body{
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    overflow-x: hidden !important;
}
mark{
    padding: 0;
}
button{
    outline: none;
}
select::after{
    position: absolute;
    top: 0;
    right: 0;
    border-right: solid 0.1rem  #444;
    height: 4.4rem;
    width: 4rem;
    content: 'a';
}
select{
    background-color: #fff;
    border: 0.1rem solid #666;
    position: relative;
    font-size: 1rem;
    height: 2rem;
    width: 10rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #212529;
}
select:focus{
    border-color:#666;
    box-shadow: none;
}

/* table{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px 100px;
}
td,th{
    border: 1px solid black;
    padding: 10px;
}
td{
    text-align: left;
} */
mark{
    background-color: yellow;
}